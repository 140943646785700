var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showPage ? _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "page-bg",
    style: {
      backgroundImage: 'url(' + require('../../assets/404.jpg') + ')'
    }
  }), _c('p', {
    staticClass: "tips"
  }, [_vm._v(" 您访问的页面不存在，或者您没有访问权限，请重新登录~ ")]), _c('div', {
    staticClass: "shortcut"
  }, [_c('el-button', {
    attrs: {
      "size": "small",
      "type": "default"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回上一页")]), _c('el-button', {
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.replace('/login');
      }
    }
  }, [_vm._v("登录")])], 1)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }