<template>
  <transition name="fade">
    <div class="wrap" v-if="showPage">
      <div class="container">
        <div
          class="page-bg"
          :style="{
            backgroundImage: 'url(' + require('../../assets/404.jpg') + ')'
          }"
        ></div>
        <p class="tips">
          您访问的页面不存在，或者您没有访问权限，请重新登录~
        </p>
        <div class="shortcut">
          <el-button size="small" type="default" @click="$router.go(-1)"
            >返回上一页</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="$router.replace('/login')"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </transition>
</template>
<script type="text/javascript">
export default {
  data() {
    return {
      showPage: false, //显示页面
      timer: null //定时器
    };
  },
  created() {
    this.timer = setTimeout(() => {
      this.showPage = true;
      clearTimeout(this.timer);
    }, 500);
  }
};
</script>
<style media="screen" scoped lang="scss">
.wrap {
  position: relative;
  height: 100vh;
  background: 100%;
  background: #fff;
}
.container {
  width: 800px;
  height: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -200px 0 0 -400px;
}
.page-bg {
  width: 800px;
  height: 400px;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}
.tips {
  line-height: 40px;
  text-align: center;
  color: #f56c6c;
}
.shortcut {
  font-size: 14px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 420px;
  bottom: 60px;
  button {
    display: inline-block;
    margin: 0 10px;
  }
}
</style>
